<template>
    <v-app>
        <v-main>
            <v-container class="d-flex justify-center" fluid>
                <v-card elevation="0" width="100%" max-width="100%">
                    <v-card-title>
                        <span class="headline">{{$t('general.titles.tracking')}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col cols="6" class="py-0">
                                <span class="grey--text">{{$t('records.label.titles.product')}}:</span>
                                <p class="black--text ml-1 mb-2">
                                    {{trackingDataModel.product}}</p>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <span class="grey--text">{{trackingDataModel.loteSafra}}:</span>
                                <p class="black--text ml-1 mb-2">
                                    {{trackingDataModel.lot}}</p>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <span class="grey--text">{{$t('records.label.titles.producedBy')}}:</span>
                                <p class="black--text ml-1 mb-2">
                                    {{trackingDataModel.companyOri}}</p>
<!--                                <p class="black&#45;&#45;text ml-1 mb-2">-->
<!--                                    {{trackingDataModel.addressOri}}</p>-->
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <span class="grey--text">{{$t('records.label.titles.distributedBy')}}:</span>
                                <p class="black--text ml-1 mb-2">
                                    {{trackingDataModel.companyDest}}</p>
<!--                                <p class="black&#45;&#45;text ml-1 mb-2">-->
<!--                                    {{trackingDataModel.addressDest}}</p>-->
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="12" >
                                <map-box :displayDrawControlsDefault="false"
                                         :drawControl="false"
                                         :geolocateControl="true"
                                         :navigationControl="true" :scaleControl="true"
                                         :searchControl="true" ref="mapLocations"
                                         v-on:loadComplete="loadMapComplete"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import MapBox from '@/components/vueMapbox/MapBox.vue';

    export default {
        name: 'TrackingQR',
        components: {MapBox},
        meta: {
            title: 'AgTrace Tracking'
        },
        data: () => ({
            trackingDataModel:{
                lot:'',
                loteSafra:'',
                product:'',
                addressOri:'',
                addressDest:'',
                companyOri:'',
                companyDest:''
            }
        }),
        created() {
            this.tracking();
            this.languajeKeys([this.$toast]);
        },
        methods: {
            ...mapActions({
                tracking: 'tracking/tracking',
                fetchTrackingData: 'tracking/fetchTrackingData',
                languajeKeys: 'general/getLanguajeKeys'
            }),
            // MAP
            loadMapComplete() {

                this.fetchTrackingData([{sdi_origin:this.getQueryVariable('ori'),sdi_destination:this.getQueryVariable('des')},this.$toast]).finally(()=>{
                    if (this.IsJsonString(this.trackingData.sdi_destination.location) && this.IsJsonString(this.trackingData.sdi_origin.location)) {
                        const re = '/%20|\+/g';
                        this.$refs.mapLocations.trackingDraw(this.trackingData);
                        this.trackingDataModel.companyOri = this.getQueryVariable('c_ori');
                        this.trackingDataModel.companyDest = this.getQueryVariable('c_dest');
                        this.trackingDataModel.addressOri = this.getQueryVariable('a_ori').replace(re,' ');
                        this.trackingDataModel.addressDest = this.getQueryVariable('a_dest').replace(re,' ');
                        this.trackingDataModel.addressDest = this.getQueryVariable('a_dest').replace(re,' ');
                        this.trackingDataModel.lot = this.getQueryVariable('lot');
                        this.trackingDataModel.product = this.getQueryVariable('prd');
                        this.trackingDataModel.loteSafra = this.getQueryVariable('lotn');
                    }
                });
            },
            /**
             * @return {boolean}
             */
            IsJsonString(json) {
                try {
                    JSON.parse(json);
                } catch (e) {
                    return false;
                }
                return true;
            },
            getQueryVariable(variable) {
                const query = window.location.search.substring(1);
                const vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] === variable) {
                        return pair[1];
                    }
                }
                return false;
            }
        },
        computed: {
            ...mapGetters({
                mapConfig: 'tracking/mapConfig',
                trackingData: 'tracking/trackingData',
            })
        }
    };
</script>
<style scoped lang="scss">
</style>
