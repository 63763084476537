import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c(VContainer,{staticClass:"d-flex justify-center",attrs:{"fluid":""}},[_c(VCard,{attrs:{"elevation":"0","width":"100%","max-width":"100%"}},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('general.titles.tracking')))])]),_c(VDivider),_c(VCardText,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('records.label.titles.product'))+":")]),_c('p',{staticClass:"black--text ml-1 mb-2"},[_vm._v(" "+_vm._s(_vm.trackingDataModel.product))])]),_c(VCol,{staticClass:"py-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.trackingDataModel.loteSafra)+":")]),_c('p',{staticClass:"black--text ml-1 mb-2"},[_vm._v(" "+_vm._s(_vm.trackingDataModel.lot))])]),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('records.label.titles.producedBy'))+":")]),_c('p',{staticClass:"black--text ml-1 mb-2"},[_vm._v(" "+_vm._s(_vm.trackingDataModel.companyOri))])]),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('records.label.titles.distributedBy'))+":")]),_c('p',{staticClass:"black--text ml-1 mb-2"},[_vm._v(" "+_vm._s(_vm.trackingDataModel.companyDest))])])],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('map-box',{ref:"mapLocations",attrs:{"displayDrawControlsDefault":false,"drawControl":false,"geolocateControl":true,"navigationControl":true,"scaleControl":true,"searchControl":true},on:{"loadComplete":_vm.loadMapComplete}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }